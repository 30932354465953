<script setup lang="ts">
import { lockBodyScroll, unlockBodyScroll } from '~/utilities/ui-utilities';
import type { DialogBreakpoints } from 'primevue/dialog';
import Button from '~/components/design-system/button.vue';
import { useAuthStore } from '~/stores/auth.store';
import Dialog from 'primevue/dialog';
import { useAccountOptions } from '~/composables/useAccountOptions';
import { dialogPassthrough } from '~/components/passthroughs/dialog';
import { XMarkIcon } from '@heroicons/vue/24/outline';
interface IModalProps {
  title?: string;
  breakpoints?: DialogBreakpoints;
  width?: string;
  active?: boolean;
  contentType?: 'form' | 'content';
}

const authStore = useAuthStore();
const siteStore = useSiteStore();
const viewport = useViewport();

const { $t }: any = useNuxtApp();
const emits = defineEmits(['closeModal']);
const displayModal: globalThis.Ref<boolean> = ref(false);
const props = withDefaults(defineProps<IModalProps>(), {
  active: false,
});

const currentPage: globalThis.ComputedRef<string | string[]> = computed(() => {
  if (
    !authStore.getAccountModalPage ||
    (!authStore.getAccountModalPage && !authStore.getAccountModalSubPage)
  )
    return;
  return authStore.getAccountModalPage || useAccountOptions()[0].id;
});

const component = computed(() => {
  return (
    useAccountOptions().find(({ id }) => id === currentPage.value)?.component ||
    useAccountOptions()[0].component
  );
});
const openModal = () => {
  if (props.active) {
    displayModal.value = true;
  }
};
const closeModal = () => {
  displayModal.value = false;
  unlockBodyScroll();
};
</script>

<template>
  <div>
    <div @click="openModal">
      <slot name="modal-activator"></slot>
    </div>
    <Dialog
      :modal="true"
      :closable="false"
      :draggable="false"
      :breakpoints="props.breakpoints"
      :visible="props.active"
      class="container max-h-[95vh] md:max-h-[85vh] !p-0"
      :pt="dialogPassthrough"
      :show-header="viewport.isGreaterOrEquals('tablet')"
      @show="lockBodyScroll()"
      @hide="unlockBodyScroll()"
    >
      <template #header>
        <div class="flex flex-row justify-between w-full items-center p-2.5">
          <h2 class="h-fit text-xl font-bold text-base-priority">
            {{ $t('account-options') }}
            {{
              !!authStore.currentUser
                ? `(${authStore.currentUser.primaryMobileNumber})`
                : ''
            }}
          </h2>

          <Button
            type="secondary"
            size="medium"
            rounding="md"
            @click="$emit('closeModal'), closeModal()"
          >
            <XMarkIcon />
          </Button>
        </div>
      </template>
      <UserAccountLayout>
        <!--        <slot name="modal-content" />-->
        <div v-if="!authStore.loggedIn">
          <div class="text-xl font-bold text-center">
            {{ $t('not-logged-in') }}
          </div>
          <div class="text-center">{{ $t('login-to-proceed') }}</div>
          <Button @click="siteStore.activateModal('login')">Login</Button>
        </div>
        <component :is="component" v-else :key="currentPage" />
      </UserAccountLayout>

      <template #footer>
        <slot name="footer-content"></slot>
      </template>
    </Dialog>
  </div>
</template>
<style lang="scss"></style>
