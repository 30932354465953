<script setup lang="ts">
const Icon = useIcon();
import { LogoutIcon } from 'assets/data/icons';

interface IBindings {
  'stroke-width': string;
  'fill-rule': string;
  'clip-rule': string;
}

const bindings: IBindings = {
  'stroke-width': '2',
  'fill-rule': 'evenodd',
  'clip-rule': 'evenodd',
};
</script>
<template>
  <Icon :path="LogoutIcon" class="w-8" :bindings="bindings" stroked />
</template>
